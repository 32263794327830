import * as React from 'react'
import styled from '@emotion/styled'
import { SlimTemplate } from '../templates/SlimTemplate'

const repertoire = [
  { head: 'Aaron Copland ', rows: [{ content: 'Sonata (1942)' }] },
  {
    head: 'L.v. Beethoven',
    rows: [{ content: 'Sonate A-Dur op.30/1' }, { content: 'Sonate G-Dur op.96' }]
  },
  { head: 'Johannes Brahms', rows: [{ content: 'Sonate  G-Dur op.78' }] },
  {
    head: 'Manuel de Falla',
    rows: [{ content: 'Suite popular espagñola' }, { content: 'Danse espagnole aus „La vida breve“' }]
  },
  {
    head: 'Leoš Janácek',
    rows: [{ content: 'Sonata per violino e piano (1922) ' }]
  },
  {
    head: 'Olivier Messiaen ',
    rows: [{ content: 'Louange à l‘Immortalité de Jésus ' }, { content: 'aus dem Quatuor pour la fin du temps' }]
  },
  {
    head: 'W.A.Mozart ',
    rows: [{ content: 'Sonate A-Dur KV 305' }, { content: 'Sonate  B-Dur KV 454' }]
  },
  { head: 'Maurice Ravel', rows: [{ content: 'Sonate' }] },
  {
    head: 'Franz Schubert',
    rows: [{ content: 'Sonate A-Dur op.162' }, { content: 'Sonate  D 821 „Arpeggione“' }]
  },
  { head: '' },
  { head: 'Süd/Nord!' },
  { head: 'Joaquin Turina', rows: [{ content: 'Sonate espagñola' }] },
  {
    head: 'Isaac Albeniz',
    rows: [{ content: '„Almeria“ aus Iberia für Piano solo' }]
  },
  { head: 'Pablo de Sarasate', rows: [{ content: 'Spanischer Tanz op.26' }] },
  { head: 'Manuel de Falla', rows: [{ content: 'Suite popular espagñola' }] },
  { head: 'Edvard Grieg', rows: [{ content: 'Sonate c-moll op. 45' }] }
]

const TD = styled.td`
  border: none;
`

const Programm = (props) => {
  return (
    <article>
      <table>
        <tbody>
          {repertoire.map((item) => {
            if (item.rows && item.rows.length > 0) {
              return (
                <React.Fragment>
                  {item.rows.map(({ content }, index) => {
                    const artist = index === 0 ? item.head : ''
                    return (
                      <tr>
                        <TD>{artist}</TD>
                        <TD>{content}</TD>
                      </tr>
                    )
                  })}
                </React.Fragment>
              )
            } else {
              return (
                <tr>
                  <TD>
                    <b>{item.head}</b>
                  </TD>
                </tr>
              )
            }
          })}
        </tbody>
      </table>
    </article>
  )
}

const ProgrammPage = () => {
  return (
    <SlimTemplate>
      <Programm />
    </SlimTemplate>
  )
}

export default ProgrammPage
